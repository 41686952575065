// import { VisitorApi } from '@/api/visitor/VisitorApi';
import { VisitorApi } from '@/api/visitor/VisitorApi';

export class VisitorService {
    private api = new VisitorApi();

    //获取访客人员列表
    public getWaitVisitorList(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getWaitVisitorList(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    //添加访客人员
    public addVisitor(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.addVisitor(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    //访客人员信息
    public visitorDetails(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.visitorDetails(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }

    

    // //获取人脸信息
    // public registerFaceMode(image: any, groupId: any): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         this.api.registerFaceMode(image, groupId).then((res: any) => {
    //             resolve(res.data);
    //         }).catch((err: any) => {
    //             reject(err);
    //         });
    //     });
    // }
    
}