import VisitorBaseService from './base/VisitorBaseService';

export class VisitorApi extends VisitorBaseService {
  constructor() {
    super("");
  }

  /**
   * 获取访客列表
  */
  public getWaitVisitorList(params: any): Promise<any> {
    return this.request('reception/visitor/list', this.rest.METHOD_GET, params);
  }

  /**
   * 获取待接待访客列表
  */
  public addVisitor(params: any): Promise<any> {
    return this.request('reception/save', this.rest.METHOD_POST, params);
  }

  /**
   * 获取访客信息
  */
  public visitorDetails(params: any): Promise<any> {
    return this.request('reception/detail', this.rest.METHOD_GET, params);
  }



  

}